.App {
  text-align: center;
}

input:focus {
  outline: none !important;
  border:1px solid #1e2e55;
}

textarea:focus {
  outline: none !important;
  border:1px solid #1e2e55;
}

select:focus {
  outline: none !important;
  border:1px solid #1e2e55;
}

/* TODO: choose font family */