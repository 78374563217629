.spinner-div {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: rgba(24, 47, 88, .5);
}

.spinner {
    /* Size */
    height: 5rem;
    width: 5rem;
 
    /* Create a curve at the top */
    border: 4px solid #d1d5db;
    border-top-color: #1e2e55;
    border-radius: 50%;
    animation: spinner 800ms linear infinite;    
 }
 
 @keyframes spinner {
     from {
         transform: rotate(0deg);
     }
     to {
         transform: rotate(360deg);
     }
 }

 @media (max-width: 640px) {
    .spinner-div {
        width: 100%;
        height: 100%;
    }
 }
