
/* Container for the whole layout */
.container {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden; /* Prevent scrolling the whole page */
    background-color: rgba(214, 214, 214, .3);
}

/* Main area containing navbar and content */
.main-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow: auto;
}

/* Navbar fixed at the top of main area */
.navbar {
    height: 60px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 0%;
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Main content area */
.main-content {
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto; /* Allow scrolling if content is too tall */
}

@media (max-width: 768px) {
    .main-area {
        overflow: auto;
    };
    .navbar-content {
        align-items: baseline;
        justify-content: center;
    };
}


/* MY css */

/* 
.admin{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(240, 238, 238, 1);
}

.header{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.main{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-left: 15px;
    margin-top: 100px;
    margin-bottom: 50px;
}

.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
}

.subDivs{
    margin-top: 3%;
    width: 100%;
    overflow-x: auto;
}

.title{
    font-size: 2em;
}

.deleteButton{
    width: '1.2rem';
    height: '1.3rem';
    cursor: pointer;
}

.buttonGroupDiv {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: auto;
    align-items: center;
    margin-top: 3.5%;
}

.buttonGroup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3.5%;
    margin-bottom: 3.5%;
}

@media (max-width: 640px) {
    .container{
        flex-direction: column;
        width: 100%;
    }
    .subDivs{
        width: 85%;
    }
    .title{
        font-size: 1.3em;
    }
    .buttonGroupDiv{
        flex-direction: column;
    }
    .buttonGroup{
        flex-direction: column;
        width: 100%;
    }
} 
*/

.radio-switch {
    background-color: #adbdd6;
    border-radius: 9999px;
    display: inline-flex;
    padding: 0.25rem;
    margin-top: 2rem;
}

.radio-switch__label {
    border-radius: 9999px;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
}

.radio-switch__label--selected {
    /* For selected radio only */
    background-color: #3b82f6;
    color: #fff;
}

.radio-switch__input {
    display: none;
}