.table-container {
    width: 100% !important;
    margin: auto;
    margin-top: 20px;
    /* max-width: 600px; */
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .theadGrey {
    color: rgba(117, 117, 117, 1);
    font-size: 1.1rem;
    font-weight: 600;
  } 

  .styled-table thead {
    color: #1e2e55;
    font-size: 1.1rem;
    font-weight: 600;
  } 

  .styled-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    border-bottom: 2px solid #dee2e6;
  }
  
  .styled-table td {
    padding: 10px;
    /* border-bottom: 1px solid #dee2e6; Row divider */
  }
  
  .styled-table tbody tr:last-child td {
    border-bottom: none; /* Remove border for the last row */
  }
  
  .styled-table td:first-child,
  .styled-table td:last-child,
  .styled-table th:first-child,
  .styled-table th:last-child {
    border-left: none;
    border-right: none;
  }
  
  .add-item-row {
    background-color: #f1f1f1;
    border-top: 2px dashed #dee2e6;
    border-bottom: 2px dashed #dee2e6;
    text-align: center;
  }
  
  .add-item {
    padding: 15px;
    font-size: 14px;
    color: #6c757d;
    cursor: pointer;
  }
  
  .styled-table input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #6c757d;
  }
  