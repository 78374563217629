#sm-640px
#md-768px
#xl-1024px
#xxl-1536px

html{
  width: 100%;
  height: 100vh;
}

/* :root{
  margin-top: 8.5%;
}

@media screen and (max-width: 768px) {
 :root{
  margin-top: 30%;
 }
}

@media screen and (min-width: 768px) and (max-width: 1024) {
  :root{
    margin-top: 20%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1536px){
  :root{
    margin-top: 10%;
  }
} */

a:link { text-decoration: none; }